<template>
  <form>
    <div class="row">
      <div class="col">
        <base-input :label="getTrans('messages.blocked_countries')">
          <el-select
            v-model="selectedCountry.blocked_countries"
            multiple
          >
            <el-option
              v-for="country in getCountries"
              :key="country.id"
              :label="country.name"
              :value="country.id"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-button type="primary" @click="saveUser">{{getTrans('messages.save')}}</base-button>
      </div>
    </div>
  </form>
</template>
<script>

import { mapGetters } from "vuex";
import { ElSelect, ElOption } from "element-plus";

export default {
  emits: ['save'],
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      selectedCountry: {
        show: true,
        blocked_countries: []
      }
    };
  },
  computed: {
    ...mapGetters("Country", ["getCountries"]),
    ...mapGetters("User", ["getUser"])
  },
  methods: {
    saveUser() {
      if (this.selectedCountry.blocked_countries.length === 0) {
        this.$emit('save', { blocked_countries: "" });
      } else {
        this.$emit('save', {
          blocked_targeting: {countries: this.selectedCountry.blocked_countries}
        });
      }
    }
  },
  mounted() {
    this.$store.dispatch("Country/get");
    this.selectedCountry.blocked_countries = Array.isArray(
      this.user.blocked_targeting?.countries
    )
      ? this.user.blocked_targeting.countries
      : [];
  }
};
</script>
